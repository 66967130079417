import React, { lazy } from 'react';
import { Routes } from 'constants/routeConstants';
import Home from 'modules/home';
import MyAccounts from 'modules/accounts/pages/MyAccounts';
import MfaInput from 'modules/auth/pages/MfaInput';
import ForgotPassword from 'modules/auth/pages/ForgotPassword';
import Migration from 'modules/auth/pages/Migration';
import ForcePasswordChange from 'modules/auth/pages/ForcePasswordChange';
import SignIn from 'modules/auth/pages/SignIn';
import SignUp from 'modules/auth/pages/SignUp';
import ResetPassword from 'modules/auth/pages/ResetPassword';
import Dashboard from 'modules/dashboard';
import OutdatedPassword from 'modules/auth/pages/ForgotPassword/containers/OutdatedPasswordContainer';

import BankTransfer from 'modules/payments/pages/Deposit/containers/BankTransferPaymentContainer';
import DepositMonoovaContainer from 'modules/payments/pages/Deposit/containers/DepositMonoovaContainer';

import MyFollowersWaitlist from 'modules/myFollowers/containers/waitlist';
import MyFollowersJoin from 'modules/myFollowers/containers/join';
import MyFollowersTrades from 'modules/myFollowers/containers/trades';
import DownloadsContainer from 'modules/tradingTools/containers/DownloadsContainer';
import DownloadMobileContainer from 'modules/tradingTools/containers/DownloadMobileContainer';
import ResearchContainer from 'modules/tradingTools/containers/ResearchContainer';
import TechnicalAnalysisContainer from 'modules/tradingTools/containers/TechnicalAnalysisContainer';
import MarketNewsContainer from 'modules/tradingTools/containers/MarketNewsContainer';
import ImpactNewsContainer from 'modules/tradingTools/containers/ImpactNewsContainer';
import CorrelatingContainer from 'modules/tradingTools/containers/CorrelatingContainer';
import MarketReportContainer from 'modules/tradingTools/containers/MarketReportContainer';
import PerformanceStatisticsContainer from 'modules/tradingTools/containers/PerformanceStatisticsContainer';
import VolatilityAnalysisContainer from 'modules/tradingTools/containers/VolatilityAnalysisContainer';
import NewsSentimentContainer from 'modules/tradingTools/containers/NewsSentimentContainer';
import MessagingAndAlertsContainer from 'modules/tradingTools/containers/MessagingAndAlertsContainer';
import EducationContainer from 'modules/tradingTools/containers/EducationContainer';

const ContactUs = lazy(() => import('modules/contactUs'));
const Discover = lazy(() => import('modules/discover/components/DiscoverPage'));
const DiscoverDashboard = lazy(() => import('modules/discover/pages/Dashboard'));
const MyFeeds = lazy(() => import('modules/discover/pages/MyFeeds'));
const MyFeedDetails = lazy(() => import('modules/discover/pages/MyFeedDetails'));
const Sentiment = lazy(() => import('modules/discover/pages/Sentiment'));
const NewsSearch = lazy(() => import('modules/discover/pages/Search/containers'));
const MigrateMyAccount = lazy(() => import('modules/migrateMyAccount'));
const MyFollowers = lazy(() => import('modules/myFollowers'));
const MyPerformance = lazy(() => import('modules/myPerformance'));
const UserPerformance = lazy(() => import('modules/myPerformance/containers/userPerformance'));
const Tools = lazy(() => import('modules/tools'));
const TradingCentralEmbed = lazy(() => import('modules/tradingCentralEmbed'));
const AnalystViews = lazy(() => import('modules/analystViews'));
const LeadJoin = lazy(() => import('modules/lead'));
const ReduceFees = lazy(() => import('modules/reduceFees'));
const TradingCalculators = lazy(() => import('modules/tradingCalculators'));
const VPS = lazy(() => import('modules/vps'));
const ConfirmPayment = lazy(() =>
  import('modules/payments/pages/Deposit/containers/ConfirmPaymentContainer'),
);
const Payments = lazy(() => import('modules/payments/containers/PaymentsContainer'));
const ProfileData = lazy(() => import('modules/profile/pages/ProfileData'));
const ProfileVerification = lazy(() => import('modules/profile/pages/Verification'));
const VerificationDetails = lazy(() => import('modules/verificationManagement/pages/ViewDetails'));
const EmailManagement = lazy(() => import('modules/emailManagement'));
const VerificationManagement = lazy(() => import('modules/verificationManagement'));
const UserDetails = lazy(() => import('modules/userManagement/pages/UserDetails'));
const UserManagement = lazy(() => import('modules/userManagement'));
const PaymentDetails = lazy(() => import('modules/paymentManagement/pages/ViewDetails'));
const PaymentManagement = lazy(() => import('modules/paymentManagement'));
const Security = lazy(() => import('modules/security'));
const Settings = lazy(() => import('modules/settings'));
const PageWarning = lazy(() => import('modules/pageWarning'));
const CustomFields = lazy(() => import('modules/customFields'));
const Notifications = lazy(() => import('modules/userNotifications'));
const RiskGroup = lazy(() => import('modules/riskGroup'));
const PaymentQueue = lazy(() => import('modules/paymentQueue'));
const MigrationRequests = lazy(() => import('modules/migrationRequests'));
const Operations = lazy(() => import('modules/operations'));
const TicketHistory = lazy(() =>
  import('modules/userManagement/pages/UserDetails/containers/TicketHistoryContainer'),
);
const UpgradeToPro = lazy(() => import('modules/upgradeToProV2/containers/UpgradeToProContainer'));

const TradingCentralEmbedEconomicCalendar = () => <TradingCentralEmbed page="economicCalendar" />;
const TradingCalculatorsPublic = () => <TradingCalculators isPublic />;
const TradingCentralEmbedPublicEconomicCalendar = () => (
  <TradingCentralEmbed isPublic page="economicCalendarPublic" />
);
const TradingCentralEmbedTechnicalInsight = () => <TradingCentralEmbed page="technicalInsight" />;
const SignInInternal = () => <SignIn internal />;

const AUTH_ROUTES_CONFIG = [
  {
    component: OutdatedPassword,
    path: Routes.AUTH_OUTDATED_PASSWORD,
    isPrivate: false,
  },
  {
    component: ResetPassword,
    path: Routes.AUTH_RESET_PASSWORD,
    isPrivate: false,
  },
  {
    component: MfaInput,
    path: Routes.AUTH_MFA_INPUT,
    isPrivate: false,
  },
  {
    component: ForgotPassword,
    path: Routes.AUTH_FORGOT_PASSWORD,
    isPrivate: false,
  },
  {
    component: Migration,
    path: Routes.AUTH_MIGRATION,
    isPrivate: false,
  },
  {
    component: ForcePasswordChange,
    path: Routes.AUTH_FORCE_PASSWORD_CHANGE,
    isPrivate: false,
  },
  {
    component: SignInInternal,
    path: Routes.AUTH_SIGN_IN_INTERNAL,
    isPrivate: false,
  },
  {
    component: SignIn,
    path: Routes.SIGNIN,
    isPrivate: false,
    exact: true,
  },
  {
    component: SignUp,
    path: Routes.SIGNUP,
    isPrivate: false,
  },
];
const PAYMENTS_ROUTES_CONFIG = [
  {
    component: ConfirmPayment,
    path: Routes.CONFIRM_PAYMENT,
    isPrivate: true,
  },
  {
    component: Payments,
    path: [Routes.PAYMENT, Routes.PAYMENTS],
    isPrivate: true,
  },
  {
    component: BankTransfer,
    path: [Routes.BANK_TRANSFER_PAYMENT],
    isPrivate: true,
  },
  {
    component: DepositMonoovaContainer,
    path: [Routes.PAY_ID_PAYMENT],
    isPrivate: true,
  },
];
const NEWS_ROUTES_CONFIG = [
  {
    component: DiscoverDashboard,
    path: Routes.MARKET_BUZZ_DASHBOARD_ID,
    isPrivate: true,
  },
  {
    component: Discover,
    path: Routes.MARKET_BUZZ,
    isPrivate: true,
  },
  {
    component: MyFeedDetails,
    path: Routes.MY_FEEDS_WITH_ID,
    isPrivate: true,
  },
  {
    component: MyFeeds,
    path: Routes.MY_FEEDS,
    isPrivate: true,
    exact: true,
  },
  {
    component: Sentiment,
    path: Routes.SENTIMENT,
    isPrivate: true,
  },
  {
    component: NewsSearch,
    path: Routes.NEWS_SEARCH,
    isPrivate: true,
  },
];
const FUSION_PLUS_ROUTES_CONFIG = [
  {
    component: MyFollowers,
    path: Routes.MY_FOLLOWERS,
    isPrivate: true,
  },
  {
    component: MyFollowersJoin,
    path: Routes.MY_FOLLOWERS_JOIN,
    isPrivate: true,
  },
  {
    component: MyFollowersWaitlist,
    path: Routes.MY_FOLLOWERS_WAITLIST,
    isPrivate: true,
  },
  {
    component: MyFollowersTrades,
    path: Routes.MY_FOLLOWERS_TRADES,
    isPrivate: true,
  },
];
const STAFF_ROUTES = [
  {
    component: MigrationRequests,
    path: Routes.MIGRATION_REQUESTS,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: UserDetails,
    path: Routes.USER_MANAGEMENT_DETAILS_ID,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: UserManagement,
    path: Routes.USER_MANAGEMENT,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: TicketHistory,
    path: Routes.TICKET_HISTORY_ID,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: PaymentDetails,
    path: Routes.PAYMENT_MANAGEMENT_DETAILS_ID,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: PaymentManagement,
    path: Routes.PAYMENT_MANAGEMENT,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: EmailManagement,
    path: Routes.EMAIL_MANAGEMENT,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: VerificationDetails,
    path: Routes.VERIFICATION_MANAGEMENT_DETAILS_ID,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: VerificationManagement,
    path: Routes.VERIFICATION_MANAGEMENT,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: PageWarning,
    path: Routes.PAGE_WARNING,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: RiskGroup,
    path: Routes.RISK_GROUP,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: PaymentQueue,
    path: Routes.PAYMENT_QUEUE,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: Notifications,
    path: Routes.NOTIFICATIONS,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: Security,
    path: Routes.SECURITY,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: Operations,
    path: Routes.OPERATIONS,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: Settings,
    path: Routes.SETTINGS,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: Dashboard,
    path: Routes.DASHBOARD,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: CustomFields,
    path: Routes.CUSTOM_FIELDS,
    isPrivate: true,
    staffOnly: true,
    exact: true,
  },
];
export const ROUTES_CONFIG = [
  ...AUTH_ROUTES_CONFIG,
  ...STAFF_ROUTES,
  // ...PAYMENTS_ROUTES_CONFIG,
  {
    component: MyAccounts,
    path: Routes.MY_ACCOUNTS,
    isPrivate: true,
  },
  {
    component: MigrationRequests,
    path: Routes.MIGRATION_REQUESTS,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: Tools,
    path: Routes.PLATFORMS,
    isPrivate: true,
  },
  ...FUSION_PLUS_ROUTES_CONFIG,
  {
    component: MyPerformance,
    path: Routes.MY_PERFORMANCE,
    isPrivate: true,
  },
  {
    component: UserPerformance,
    path: Routes.USER_PERFORMANCE,
    isPrivate: true,
  },
  {
    component: TradingCentralEmbedEconomicCalendar,
    path: Routes.ECONOMIC_CALENDAR,
    isPrivate: true,
  },
  {
    component: MigrateMyAccount,
    path: Routes.MIGRATE_MY_ACCOUNT,
    isPrivate: true,
  },
  {
    component: UpgradeToPro,
    path: Routes.UPGRADE_TO_PRO_V_2,
    isPrivate: true,
  },
  {
    component: ContactUs,
    path: Routes.CONTACT_US,
    isPrivate: true,
  },
  {
    component: DownloadsContainer,
    path: Routes.MT4_DOWNLOADS,
    isPrivate: true,
  },
  {
    component: DownloadMobileContainer,
    path: Routes.MT4_DOWNLOADS_MOBILE,
    isPrivate: true,
  },
  {
    component: ResearchContainer,
    path: Routes.TRADE_IDEAS_RESEARCH,
    isPrivate: true,
  },
  {
    component: TechnicalAnalysisContainer,
    path: Routes.TRADE_IDEAS_TECHNICAL_ANALYSIS,
    isPrivate: true,
  },
  {
    component: MarketReportContainer,
    path: Routes.TRADE_IDEAS_MARKET_REPORT,
    isPrivate: true,
  },
  {
    component: ImpactNewsContainer,
    path: Routes.TRADE_IDEAS_IMPACT_NEWS,
    isPrivate: true,
  },
  {
    component: CorrelatingContainer,
    path: Routes.TRADE_IDEAS_CORRELATING,
    isPrivate: true,
  },
  {
    component: PerformanceStatisticsContainer,
    path: Routes.TRADE_IDEAS_PERFORMANCE_STATISTICS,
    isPrivate: true,
  },
  {
    component: VolatilityAnalysisContainer,
    path: Routes.TRADE_IDEAS_VOLATILITY_ANALYSIS,
    isPrivate: true,
  },
  {
    component: NewsSentimentContainer,
    path: Routes.LEARNINGS_NEWS_SENTIMENT,
    isPrivate: true,
  },
  {
    component: MessagingAndAlertsContainer,
    path: Routes.MESSAGING_AND_ALERTS,
    isPrivate: true,
  },
  {
    component: EducationContainer,
    path: Routes.LEARNINGS_NEWS_EDUCATION,
    isPrivate: true,
  },
  {
    component: ProfileData,
    path: Routes.PROFILE,
    isPrivate: true,
    exact: true,
  },
  {
    component: ProfileVerification,
    path: Routes.PROFILE_VERIFICATION,
    isPrivate: true,
  },
  {
    component: TradingCalculators,
    path: Routes.TRADING_CALCULATORS,
    isPrivate: true,
  },
  {
    component: TradingCalculatorsPublic,
    path: Routes.PUBLIC_CALCULATORS,
    isPrivate: false,
  },
  {
    component: TradingCentralEmbedTechnicalInsight,
    path: Routes.TECHNICAL_INSIGHT,
    isPrivate: true,
  },
  {
    component: TradingCentralEmbedPublicEconomicCalendar,
    path: Routes.PUBLIC_ECONOMIC_CALENDAR,
    isPrivate: false,
  },
  {
    component: ReduceFees,
    path: Routes.REDUCE_FEES,
    isPrivate: true,
  },
  ...NEWS_ROUTES_CONFIG,
  {
    component: AnalystViews,
    path: Routes.ANALYST_VIEWS,
    isPrivate: true,
  },
  {
    component: Dashboard,
    path: Routes.DASHBOARD,
    isPrivate: true,
    staffOnly: true,
  },
  {
    component: LeadJoin,
    path: Routes.LEAD_JOIN,
    isPrivate: false,
  },
  {
    component: VPS,
    path: Routes.VPS,
    isPrivate: true,
  },
  {
    component: Home,
    path: Routes.HOME,
    isPrivate: true,
    exact: true,
    allAuthorized: true,
  },
];
